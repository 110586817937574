<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Route Details</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="routeData.name"
              label="Route"
              outlined
              dense
              filled
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="routeData.Description"
              label="Description"
              outlined
              dense
              filled
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-tabs background-color="tabToolbar">
              <v-tab :to="`/routes/${id}`">Outlets</v-tab>
              <v-tab :to="`/routes/${id}/calls`">Call Schedules</v-tab>
            </v-tabs>
          </v-col>

          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters("territory", ["territories"]),
    ...mapGetters("salesEmployee", ["salesEmployees"]),
  },
  data() {
    return {
      search: "",
      id: null,
      searchInput: null,
      dataItem: {},
      region: {},
      bpMasterData: [],
      outlets: [],
      filteredOutlets: [],
      record: {},
      routeData: {},
      editMode: true,
      selectionType: "leaf",
      trueValue: true,
      territory: {},
      dialog: false,
      dialog2: false,
      modal2: false,
      modal3: false,
      startTime: null,
      endTime: null,
      calldialog: false,
      regiondialog: false,
      repeatData: [
        { name: "Never", value: "N" },
        { name: "Weekly", value: "W" },
        { name: "Fortnightly", value: "F" },
        { name: "Monthly", value: "M" },
        { name: "Annually", value: "A" },
      ],
      headers: [
        { text: "Name", value: "CardName" },
        { text: "Category", value: "CardCode" },
        { text: "Region", value: "Territory" },
        { text: "Address", value: "Address" },
        { text: "Description", value: "description" },
      ],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getRoutes",
      immediate: true,
    },
  },
  methods: {
    getRoutes(id) {
      const self = this;
      self.id = id;
      this.loading = true;
      this.$store
        .dispatch("get", `/routes_planning/${id}`)
        .then((res) => {
          self.routeData = res.ResponseData;
          self.outlets = res.ResponseData.bpartners;
          self.countries = res.ResponseData.territories;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    addItem() {
      this.dialog = true;
      this.dialog2 = true;
    },
    addCall() {
      this.calldialog = true;
    },
    openRegionModal() {
      this.regiondialog = true;
    },
    selectItem(data) {
      this.territory = data;
      this.regiondialog = false;
      const id = data.id;
      const self = this;
      self.loading = true;
      // api call
      this.$store
        .dispatch("get", `/territory/${id}`)
        .then((res) => {
          console.log(res, "res");
          self.bpMasterData = res.outlets;
          self.filteredOutlets = res.outlets;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });

      // end
    },
    setDate(date) {
      this.record.CallDate = date;
    },
    saveData() {
      const route_id = this.$route.params.id;
      const outlets = this.record.bpartners;
      const regions = this.territory.id;

      const data = {
        route_id: route_id,
        bpartners: outlets,
        region_id: regions,
      };
      const url = "/route_outlets";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          this.$refs.snackbar.show(err, "red");
        });
    },
    saveCalls() {
      const route_id = this.$route.params.id;
      const outlets = this.record.bpartners;
      const beginTime = this.startTime;
      const stopTime = this.endTime;

      const data = {
        RouteCode: route_id,
        CardCode: outlets,
        CallTime: beginTime,
        CallEndTime: stopTime,
      };
      // console.log(data, "calls");
      const url = "/calls";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      //logic comes here
      const url = "/routes_planning/" + this.$route.params.id;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          console.log(res, "data");
          // self.$refs.snackbar.show("Channel created successfully", "green");
          self.$router.push("/routes_planning");
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    // this.getBpMaster();
  },
};
</script>
<style scoped>
.choice {
  padding: 2px;
}
</style>
